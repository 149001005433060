import React, { Component } from "react"
import get from "lodash/get"
import Helmet from "react-helmet"
// import ArticlePreview from "../components/article-preview"
import { Link, graphql } from "gatsby"
import Template from "../components/layout"
import "../components/trip.css"


export class TripIndex extends Component {
  render() {
    const trips = get(this, "props.data.allContentfulTrip.edges")
    const siteTitle = get(this, "props.data.site.siteMetadata.title")

    return (
      <Template>
        <section className="about-us">
          <div className="container">
            <div className="author-box">
              <div className="row">
                {trips.map(({ node }) => {
                  return (
                    <div className="col-lg-4 col-md-12 text-left">
                      <div className="author-image">
                        <Link to={node.slug}><img
                          src={node.image.fluid.src}
                          alt="avatar"
                          className="avatar-img"
                        /></Link>
                      </div>
                      <div className="author-text">
                        <h1 className="has-animation animate-in" data-delay="10">
                          <Link to={node.slug}><b>{node.title}</b></Link>
                        </h1>
                        <p className="has-animation animate-in" data-delay="20">
                          {node.excerpt}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </Template>
    )
  }
}

export default TripIndex


export const pageQuery = graphql`
  query TripIndexQuery {
    allContentfulTrip {
      edges {
        node {
          id
          slug
          title
          excerpt
          image {
            fluid {
              src
            }
          }
          carousel {
            fluid {
              aspectRatio
              src
            }
          }
        }
      }
    }
  }
`
